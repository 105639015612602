export default {
  siteTitle: 'Get bonus',
  blogerName: 'LEPZIN',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/lepzera'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@LEPZIN777'
    },
    {
      name: 'instagram',
      url: 'https://www.youtube.com/@qashbackoficial'
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c7a533215',
      gameTitle: 'Book of Kemet',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/c572a537d',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c44d83113',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/c695dd048',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/ce580838c',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cf6ca96ce',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c864e812d',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c22bea263',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c2aefd90b',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c8530c1af',
      gameTitle: 'JET AIR (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>LEP</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'LEP',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
